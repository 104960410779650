import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import LightButton from '../buttons/light_button'

import { Link } from 'gatsby'

const PageHeader = ({
  background,
  title,
  subtitle,
  buttonTitle,
  buttonLink,
  thisPage,
  className,
  children,
  previousPage,
  previousPageLink,
  productLogo,
  productColor
}) => {
  return (
    <BackgroundImage fluid={background} className={`page-header ${className}`}>
      <div className={`overlay-page-header ${className}`}></div>
      <div className="page-header-inner wrap">
        <div className="col-md-5 mx-auto">
          <div className="project-client-circle">
            <img src={productLogo} alt="explorelogy product logo" />
            <span
              style={{ backgroundColor: `${productColor}` }}
              className="mask"
            ></span>
          </div>
          <div className="page-title">
            <h2>{title}</h2>
          </div>
          <div className="page-subtitle">
            <p>{subtitle}</p>
          </div>
          {buttonTitle
            ? (
            <div>
              <div style={{ height: 30 }}></div>
              <LightButton title={buttonTitle} />
            </div>
              )
            : null}

          <div style={{ height: 30 }}></div>
          <div className="breadcrumb-explorelogy">
            <div className="breadcrumb-inner">
              <span>
                <Link to="/">Explorelogy</Link>
              </span>

              <span className="link-item">
                <Link to={previousPageLink}>{previousPage}</Link>
              </span>

              <span>{thisPage}</span>
            </div>
          </div>
          <div style={{ height: 300 }}></div>
          {children}
        </div>
      </div>
    </BackgroundImage>
  )
}

export default PageHeader
