import React from 'react'
import Layout from '../../components/layout'

import { useStaticQuery, graphql } from 'gatsby'

import SEO from '../../components/seo'
import PageHeader from '../../components/header/product-page-header'
import Image from 'gatsby-image'

const EduplusLms = () => {
  const data = useStaticQuery(graphql`
    query EduplusLms {
      bgImage: file(relativePath: { eq: "blog/bg-page-title.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eduplusLogo: file(relativePath: { eq: "products/eduplusLogo.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            src
          }
        }
      }
      eduplusImage: file(relativePath: { eq: "products/eduplusImage.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const title = 'Eduplus System'

  return (
    <Layout bgColor>
      <SEO title="Eduplus Learning Management System" />
      <PageHeader
        productColor={'#71cbcc'}
        productLogo={data.eduplusLogo.childImageSharp.fluid.src}
        className="pink"
        title={title}
        subtitle="Technologies that transform learning"
        background={data.bgImage.childImageSharp.fluid}
        previousPage="Products"
        previousPageLink="/products/"
        thisPage={title}
      />
      <div className="container cases-project-image pb-5">
        <div
          style={{
            position: 'relative',
            zIndex: 10,
            backgroundColor: '#fff'
          }}
        >
          <Image
            fluid={data.eduplusImage.childImageSharp.fluid}
            style={{
              border: '15px solid #fff',
              marginTop: -270,
              width: '100%',
              position: 'relative'
            }}
            alt=""
          />
        </div>
      </div>
      <div className="col-md-6 pt-5 pb-5 mx-auto">
        <div className="row">
          <div className="col-md-3 text-left text-md-center">
            <h4 className="m-0">Overview</h4>
          </div>
          <div className="col-md-9">
            <p>
              Moving into a digital age, education and technology are two
              sectors that have become evermore interdependent. We have created
              a product that utilises IT resources to make education processes
              efficient and effective and enable a safe and reliable environment
              for students to work within.
            </p>
            {/*
            <ul style={{ listStyle: "none", marginLeft: 0, paddingLeft: 0 }}>
              <li style={{ paddingBottom: 5 }}>Client: Vodafone </li>
              <li style={{ paddingBottom: 5 }}>Industry: Retail Industry</li>
              <li style={{ paddingBottom: 5 }}>
                Service: IT Consultancy, Cloud Computing and Cyber Security
              </li>
              <li style={{ paddingBottom: 5 }}>
                Via:{" "}
                <Link className="link" to="">
                  digitalmarketinginstitute.com
                </Link>
              </li>
            </ul> */}
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-md-3 text-left text-md-center">
            <h4 className="m-0">Challenge</h4>
          </div>
          <div className="col-md-9">
            <p>
              With students increasingly relying on tuition classes, the
              capacity for education institutes and mass classes to handle the
              growing number of students who enroll and to provide education in
              a safe and controlled environment is becoming more difficult. To
              address this challenge, we have designed a technology that
              connects the institute, teachers, students as well as parents to
              one host.
            </p>
          </div>
        </div>
        <div className="row pt-5 pb-5">
          <div className="col-md-3  text-left text-md-center">
            <h4 className="m-0">Solution</h4>
          </div>
          <div className="col-md-9">
            <p>
              Our Smart Card solution is an individual e-version of a physical
              ID, and enables an easy record system of all information and
              activities on an online database. With this, students and parents
              have access to class schedules, examination status and results,
              payments etc. and the institute has access to data such as student
              details, attendance, student progress etc. which will be evaluated
              strictly for educational/ professional requirements. Emergency
              notifications and other important details will also be shared
              across an SMS alert system enabled by this software.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EduplusLms
